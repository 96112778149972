import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import ApiHeader from "../Common/ApiHeader";
import ErrorHandler from "../Common/ErrorHandler";
import moment from "moment";
import conf from "../../config/conf_sql.json";
import Hashids from "hashids";
const hashids = new Hashids(conf.secret, 10);

const LastMinuteCancelWarning = ({ club_nick, crewId }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [activity, setActivity] = useState([]);
  const [hideForDay, setHideForDay] = useState(false);
  const [restrictTime, setRestrictTime] = useState(12);

  useEffect(() => {
    const lastHiddenTime = localStorage.getItem(
      club_nick.toUpperCase() + "_hideLastMinuteCancelWarningUntil"
    );
    const now = moment();
    if (lastHiddenTime && now.isBefore(moment(lastHiddenTime))) {
      return; // Do not show the warning
    }

    const checkLastMinuteCancel = async () => {
      if (!crewId) return;
      try {
        const response = await ApiHeader.get(
          `/api/v1/crews/${crewId}/lastminutecancel`
        );
        if (response.data.length > 0) {
          const recentActivity = response.data.filter((activity) =>
            moment(activity.activity_time)
              .subtract(conf.time_offset)
              .isAfter(moment().subtract(1, "days"))
          );
          if (recentActivity.length > 0) {
            setActivity(recentActivity);
            // time_difference가 0인 항목이 있는지 확인하여 limitHours 설정
            const hasTimeDifferenceZero = recentActivity.some(
              (act) => act.time_difference === 0
            );
            setRestrictTime(hasTimeDifferenceZero ? 24 : 12);
            setDialogOpen(true);
          }
        }
      } catch (e) {
        ErrorHandler(e, "LastMinuteCancelWarning.js, checkLastMinuteCancel");
      }
    };

    checkLastMinuteCancel();
  }, [crewId]);

  const handleClose = () => {
    if (hideForDay) {
      localStorage.setItem(
        club_nick.toUpperCase() + "_hideLastMinuteCancelWarningUntil",
        moment(activity[0].activity_time)
          .subtract(conf.time_offset)
          .add(1, "days")
          .toISOString()
      );
    }
    setDialogOpen(false);
  };

  const handleCheckboxChange = (event) => {
    setHideForDay(event.target.checked);
  };

  return (
    <div>
      <Dialog open={dialogOpen} onClose={handleClose}>
        <DialogTitle>Last-Minute Cancel Warning</DialogTitle>
        <DialogContent>
          <Typography sx={{ mb: 2 }}>
            최근 모임 시간 임박 취소 목록은 다음과 같습니다
          </Typography>
          {activity.map((act, index) => (
            <Link
              key={index}
              to={`/${club_nick}/activity/${hashids.encode(act.activity_id)}`}
              style={{ color: "#3f50b5", textDecoration: "inherit" }}
            >
              <Typography key={index} sx={{ mt: 1 }}>
                {moment(act.activity_time)
                  .subtract(conf.time_offset)
                  .format("YYYY-MM-DD HH:mm")}
                <br />
                {act.name}
              </Typography>
            </Link>
          ))}
          <Typography sx={{ mt: 2, mb: 2 }}>
            {restrictTime}시간동안 모임 신청이 제한됩니다.
          </Typography>
          <Typography sx={{ mt: 2, mb: 2 }}>
            모임 시간 임박해서 취소하지 않도록 주의 부탁드립니다.
          </Typography>
          <FormControlLabel
            control={
              <Checkbox checked={hideForDay} onChange={handleCheckboxChange} />
            }
            label="이 창을 숨기기"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default LastMinuteCancelWarning;
