import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  TextField,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Checkbox,
  Box,
} from "@mui/material";
import TopAppBar from "../Main/TopAppBar";
import ApiHeader from "../Common/ApiHeader";
import conf from "../../config/conf_sql.json";
import Hashids from "hashids";
import ErrorHandler from "../Common/ErrorHandler";

const hashids = new Hashids(conf.secret, 10);

const ClubSignUp = () => {
  const { club_nick, crew_id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [clubInfo, setClubInfo] = useState({ id: 0, name: "", info: "" });
  const [memberInfo, setMemberInfo] = useState({ id: 0, nick: "" });
  const [crewInfo, setCrewInfo] = useState({});
  const [crewId, setCrewId] = useState("");
  const [vCheck, setCheck] = useState(false);
  const [open, setOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [isOK, setIsOK] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(false);

  // location.state에서 timeAuthorized 값 가져오기
  const { timeAuthorized } = location.state || { timeAuthorized: 0 };
  useEffect(() => {
    const currentTime = Date.now();
    if (currentTime - timeAuthorized <= 10000) {
      // 10초 이내인지 확인
      setIsAuthorized(true);
    } else {
      setIsAuthorized(false);
    }
  }, [timeAuthorized]);

  useEffect(() => {
    const fetchClubInfo = async () => {
      try {
        const response = await ApiHeader.get(`/api/v1/clubs/nick/${club_nick}`);
        if (response.data) {
          setQuestions(response.data.question.split("\n"));
          setClubInfo({
            id: response.data.id,
            name: response.data.name,
            info: response.data.info,
          });
        } else {
          navigate("/");
        }
      } catch (e) {
        ErrorHandler(e, "ClubSignUp.js, fetchQuestions, get club info");
      }
    };

    fetchClubInfo();
  }, [club_nick]);

  useEffect(() => {
    const fetchCheckBan = async () => {
      try {
        const response = await ApiHeader.get(
          `/api/v1/members/${hashids.encode(
            memberInfo.id
          )}/clubs/${hashids.encode(clubInfo.id)}`
        );
        if (response.data) {
          if (response.data.some((crew) => crew.state === -1)) {
            setIsAuthorized(false);
          }
        }
      } catch (e) {
        ErrorHandler(e, "ClubSignUp.js, fetchCheckBan, get ban info");
      }
    };

    fetchCheckBan();
  }, [clubInfo, memberInfo]);

  useEffect(() => {
    const fetchData = async () => {
      const token = getToken();
      if (token) {
        await fetchMemberId(token);
        await fetchCrewId(token);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const initialAnswers = questions.map(
      (question) => crewInfo[question] || ""
    );
    setAnswers(initialAnswers);
  }, [crewInfo, questions]);

  const getToken = () => {
    const token = localStorage.getItem("TOKEN_CREWWITH");
    return token ? token.replace(/"/g, "") : null;
  };

  const fetchMemberId = async (token) => {
    try {
      const response = await ApiHeader.get(
        `/api/v1/accesslogs/${token}/members`
      );
      if (response.data.length === 0) {
        navigate(`/signup/`, {
          state: { isAuthorized: true },
        });
      } else {
        setMemberInfo({
          id: response.data[0].id,
          nick: response.data[0].name,
        });
      }
    } catch (e) {
      ErrorHandler(e, "ClubSignUp.js, fetchMemberId, get member info");
    }
  };

  const fetchCrewId = async (token) => {
    try {
      const response = await ApiHeader.get(
        `/api/v1/accesslogs/${token}/clubs/${club_nick}/crews`
      );
      if (response.data.length > 0) {
        setCrewId(hashids.encode(response.data[0].id));
        setCrewInfo(JSON.parse(response.data[0].info));
      }
    } catch (e) {
      ErrorHandler(e, "ClubSignUp.js, fetchCrewId, get crew info");
    }
  };

  const handleAnswerChange = (index, value) => {
    setCrewInfo((prevCrewInfo) => ({
      ...prevCrewInfo,
      [questions[index]]: value,
    }));

    setAnswers((prevAnswers) => {
      const newAnswers = [...prevAnswers];
      newAnswers[index] = value;
      return newAnswers;
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAgree = async () => {
    if (isOK) {
      const questionAnswerPairs = answers.reduce((result, answer, index) => {
        const question = questions[index] || `Question ${index + 1}`;
        result[question] = answer;
        return result;
      }, {});
      const json_qa = JSON.stringify(questionAnswerPairs);

      try {
        if (crewId === "") {
          await ApiHeader.post("/api/v1/crews", {
            club_id: clubInfo.id,
            member_id: memberInfo.id,
            nick: memberInfo.nick.trim(),
            info: json_qa || "{}",
          });
          if (club_nick.toUpperCase() === "YRC") {
            localStorage.setItem(`welcome_${club_nick.toUpperCase()}`, "true");
          }
        } else {
          await ApiHeader.put(`/api/v1/crews/${crewId}`, {
            club_id: clubInfo.id,
            member_id: memberInfo.id,
            nick: memberInfo.nick.trim(),
            info: json_qa,
          });
        }
        if (crew_id) {
          navigate(-1);
        } else {
          navigate(`/${club_nick}`);
          window.location.reload();
        }
      } catch (e) {
        ErrorHandler(
          e,
          `ClubSignUp.js, handleAgree, ${
            crewId === "" ? "post" : "put"
          } crew info`
        );
      }
    }
    setOpen(false);
  };

  const handleSubmit = () => {
    if (
      memberInfo.nick.length < 1 ||
      answers.length !== questions.length ||
      !vCheck
    ) {
      setDialogMessage("모두 작성해 주시기 바랍니다.");
      setIsOK(false);
    } else {
      setDialogMessage(
        crew_id
          ? `${clubInfo.name} 회원 정보 변경하시겠습니까?`
          : `환영합니다. 가입 하시겠습니까?`
      );
      setIsOK(true);
    }
    setOpen(true);
  };

  return isAuthorized && (!crew_id || crew_id === crewId) ? (
    <div>
      <TopAppBar club_nick={club_nick} />
      <Box
        sx={{
          maxWidth: "800px",
          margin: 1,
        }}
      >
        <Typography sx={{ mt: 2, ml: 2, fontSize: 36 }}>
          {clubInfo.name}
        </Typography>
        <Typography style={{ margin: 20, whiteSpace: "pre-wrap" }}>
          {clubInfo.info}
        </Typography>
        <FormControl fullWidth>
          <FormLabel htmlFor={`crew_nick`}>이름</FormLabel>
          <TextField
            fullWidth
            type="text"
            size="small"
            sx={{ mb: 2 }}
            name="crew_nick"
            value={memberInfo.nick}
            onChange={(event) =>
              setMemberInfo({ ...memberInfo, nick: event.target.value })
            }
          />
          {questions.map((question, index) => (
            <div key={index}>
              <FormLabel htmlFor={`answer${index}`}>{question}</FormLabel>
              <br />
              <TextField
                multiline
                fullWidth
                type="text"
                size="small"
                sx={{ mb: 2 }}
                name={`answer${index}`}
                value={crewInfo[question] || ""}
                onChange={(event) =>
                  handleAnswerChange(index, event.target.value)
                }
              />
            </div>
          ))}
          <Typography sx={{ color: "gray", fontSize: "small", mt: 2 }}>
            [클럽 안내 사항]
            <br />
            . 클럽 활동 간 촬영되는 사진들은 클럽 홍보 등에 사용될 수 있으며
            참석 시 초상권에 동의함으로 간주합니다. 클럽 외 무단 사용은
            금지됩니다.
            <br />
            . 자발적 동의 하에 참여한 활동 간에 발생할 수 있는 부상에 대해서는
            본 클럽은 책임을 지지 않습니다.
            <br />
            . 회원 상호 간의 형사 민사 상의 문제에 대해서 본 클럽은 책임을 지지
            않습니다.
            <br />
            클럽 안내 사항에 대한 동의를 거부할 권리가 있으며, 미동의시 클럽
            가입이 불가합니다.
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={vCheck}
                onChange={(e) => setCheck(e.target.checked)}
              />
            }
            label="클럽 안내 사항에 동의합니다."
          />
          <Button variant="contained" onClick={handleSubmit}>
            {crew_id ? "수정" : "등록"}
          </Button>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="dialog-title">
              {crew_id ? "정보 수정" : "클럽 가입"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="dialog-description">
                {dialogMessage}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              {isOK && <Button onClick={handleClose}>취소</Button>}
              <Button onClick={handleAgree} autoFocus>
                확인
              </Button>
            </DialogActions>
          </Dialog>
        </FormControl>
      </Box>
    </div>
  ) : (
    <div>
      <TopAppBar club_nick={club_nick} />
      잘못된 접근입니다.
    </div>
  );
};

export default ClubSignUp;
