import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import TopAppBar from "../Main/TopAppBar";
import ActivityList from "./ActivityList";
import ActivityCalendar from "./ActivityCalendar";
import { Button } from "@mui/material";
import useCrewData from "../Common/CrewData";
import ClubPublicInfo from "../Club/ClubPublicInfo";

function Activities() {
  const { club_nick } = useParams();
  const { memberId, clubId, crewId, adminLevel } = useCrewData(club_nick);

  return (
    <div>
      <TopAppBar club_nick={club_nick} />
      {adminLevel > 0 ? (
        <>
          <br />
          <ActivityCalendar club_nick={club_nick} />
          {adminLevel >= 5 && (
            <Button
              variant="contained"
              sx={{ mt: 0.5, mr: 3, display: "flex", ml: "auto" }}
            >
              <Link
                to={`/${club_nick}/activity/create`}
                state={{ timeAuthorized: Date.now() }}
                style={{ color: "inherit", textDecoration: "inherit" }}
              >
                모임 추가
              </Link>
            </Button>
          )}
          <ActivityList
            text="Following Activities"
            order="asc"
            api={`/api/v1/activities/club/${clubId}/following/day?state=1&select=id,activity_time,name,location,id as activity_id,activity_time as sort`}
            club_nick={club_nick}
            adminLevel={adminLevel}
          />
          <br />
          <ActivityList
            text="Previous Activities"
            order="desc"
            api={`/api/v1/activities/club/${clubId}/previous/day?state=1&select=id,activity_time,name,location,id as activity_id,activity_time as sort`}
            club_nick={club_nick}
            adminLevel={adminLevel}
            needSearch={true}
          />
        </>
      ) : (
        <ClubPublicInfo memberId={memberId} crewId={crewId} />
      )}
    </div>
  );
}

export default Activities;
