import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import moment from "moment";
import conf from "../../config/conf_sql.json";
import Hashids from "hashids";
const hashids = new Hashids(conf.secret, 10);
import ApiHeader from "../Common/ApiHeader";
import ErrorHandler from "../Common/ErrorHandler";

function LimitList({ club_nick, adminLevel }) {
  const [limitList, setLimitList] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);

  useEffect(() => {
    if (adminLevel < 7) return;
    ApiHeader.get(`/api/v1/crews/club/${club_nick}/limit`)
      .then((response) => {
        setLimitList(response.data);
      })
      .catch((e) => {
        ErrorHandler(e, "LimitList.js, useEffect, get limited users");
      });
  }, [club_nick, adminLevel]);

  const handleCancelLimit = (id) => {
    ApiHeader.put(
      `/api/v1/crews/${hashids.encode(id)}/attendees/updated_time/null`
    )
      .then(() => {
        setLimitList(limitList.filter((user) => user.id !== id));
        handleClose();
      })
      .catch((e) => {
        ErrorHandler(e, "LimitList.js, handleCancelLimit, cancel limit user");
      });
  };

  const handleClickOpen = (id) => {
    setSelectedUserId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedUserId(null);
  };

  return (
    <div>
      <Typography sx={{ mt: 2, ml: 2 }}>제한된 크루 리스트</Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>이름</TableCell>
            <TableCell>해제예정시점</TableCell>
            <TableCell>제한</TableCell>
          </TableRow>
        </TableHead>
        {adminLevel >= 7 && (
          <TableBody>
            {limitList.map((user) => (
              <TableRow key={user.id}>
                <TableCell
                  key={user.id}
                  sx={{
                    minWidth: 48,
                    maxWidth: "12ch",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  <Link
                    to={`/${club_nick}/crew/${hashids.encode(user.id)}`}
                    style={{ color: "#3f50b5", textDecoration: "inherit" }}
                  >
                    {user.nick}
                  </Link>
                </TableCell>
                <TableCell>
                  {moment(user.limit_end_time)
                    .subtract(conf.time_offset)
                    .format("YYYY-MM-DD HH:mm")}
                </TableCell>
                <TableCell>
                  {adminLevel >= 8 && (
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => handleClickOpen(user.id)}
                    >
                      해제
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>해제 확인</DialogTitle>
        <DialogContent>
          <DialogContentText>
            이 사용자의 제한을 해제하시겠습니까?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            취소
          </Button>
          <Button
            onClick={() => handleCancelLimit(selectedUserId)}
            color="primary"
            autoFocus
          >
            해제
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default LimitList;
