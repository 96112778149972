import React, { useEffect, useState, useMemo, useCallback } from "react";
import Calendar from "react-calendar";
import moment from "moment";
import ActivityCardList from "./ActivityCardList";
import "react-calendar/dist/Calendar.css";
import conf from "../../config/conf_sql.json";
import ApiHeader from "../Common/ApiHeader";
import ErrorHandler from "../Common/ErrorHandler";
import useCrewData from "../Common/CrewData";
import "../../App.css";

function ActivityCalendar({ club_nick }) {
  const [activityList, setActivityList] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [initialLoad, setInitialLoad] = useState(true);
  const [holidays, setHolidays] = useState({
    holidaysWithYear: [],
    holidaysWithoutYear: [],
  });
  const [isRestricted, setIsRestricted] = useState(false); // Restriction flag

  const { memberId, clubId, crewId, adminLevel } = useCrewData(club_nick);

  /** 🔹 Check attendance restrictions */
  useEffect(() => {
    if (!crewId) return;

    const checkRestrictions = async () => {
      try {
        // Check cancellation count
        const cancelResponse = await ApiHeader.get(
          `/api/v1/attendees/activity/crew/${crewId}/cancel/count`
        );
        if (cancelResponse.data?.count > 0) setIsRestricted(true);

        // Check no-show count and apply restriction if necessary
        const noshowResponse = await ApiHeader.get(
          `/api/v1/attendees/activity/crew/${crewId}/noshow/count`
        );
        if (noshowResponse.data?.count > 0) {
          const restrictionDays = noshowResponse.data.count * 7;
          const restrictedUntil = moment(noshowResponse.data.last_noshow_time)
            .subtract(conf.time_offset)
            .add(restrictionDays, "days");

          if (moment().isBefore(restrictedUntil)) setIsRestricted(true);
        }
      } catch (e) {
        ErrorHandler(e, "ActivityCalendar.js > checkRestrictions()");
      }
    };

    checkRestrictions();
  }, [crewId]);

  /** 🔹 Generate API URL based on club_nick and initialLoad */
  const getApiUrl = useCallback(
    (month, initialLoad) => {
      if (!memberId) return null;

      const startOfMonth = moment(month)
        .subtract(6, "days")
        .format("YYYY-MM-DD");
      const endOfMonth = moment(month)
        .add(1, "months")
        .startOf("month")
        .add(6, "days")
        .format("YYYY-MM-DD");

      if (club_nick === "all") {
        return `/api/v1/activities`;
      } else if (club_nick === "personal") {
        return initialLoad
          ? `/api/v1/members/${memberId}/activities?start_date=${startOfMonth}&end_date=${endOfMonth}`
          : `/api/v1/members/${memberId}/activities`;
      } else {
        return initialLoad
          ? `/api/v1/clubs/${club_nick}/activities?start_date=${startOfMonth}&end_date=${endOfMonth}`
          : `/api/v1/clubs/${club_nick}/activities`;
      }
    },
    [club_nick, memberId]
  );

  /** 🔹 Fetch activities based on selected month */
  const fetchActivities = useCallback(
    async (month, initialLoad) => {
      try {
        const apiUrl = getApiUrl(month, initialLoad);
        if (!apiUrl) return;

        const response = await ApiHeader.get(apiUrl);
        setActivityList(response.data);
      } catch (e) {
        ErrorHandler(e, "ActivityCalendar.js > fetchActivities()");
      }
    },
    [getApiUrl]
  );

  /** 🔹 Restore session data and load activity data */
  useEffect(() => {
    const storedDate = sessionStorage.getItem("selectedDate");
    const storedTimestamp = sessionStorage.getItem("selectedDateTimestamp");
    const currentTime = Date.now();
    const storedMonth = moment(storedDate).format("YYYY-MM");
    const currentMonth = moment().format("YYYY-MM");

    if (
      storedDate &&
      storedTimestamp &&
      currentTime - storedTimestamp < 60000
    ) {
      setSelectedDate(new Date(storedDate));
      if (
        storedMonth !== currentMonth &&
        (adminLevel >= 2 || club_nick === "personal")
      ) {
        setInitialLoad(false);
      }
    }

    fetchActivities(currentMonth, initialLoad);
  }, [adminLevel, fetchActivities, initialLoad]);

  /** 🔹 Load holiday data */
  useEffect(() => {
    fetch("/holidays.json")
      .then((response) => response.json())
      .then((data) => setHolidays(data))
      .catch((e) => console.error("Error loading holidays:", e));
  }, []);

  /** 🔹 Render a dot on dates that have scheduled activities */
  const memoizedTileContent = useMemo(() => {
    return ({ date }) => {
      const isActivityDay = activityList.some(
        (x) =>
          moment(x.activity_time)
            .subtract(conf.time_offset)
            .format("YYYY-MM-DD") === moment(date).format("YYYY-MM-DD")
      );
      return isActivityDay ? (
        <div className="flex justify-center items-center absoluteDiv">
          <div className="dot"></div>
        </div>
      ) : null;
    };
  }, [activityList]);

  /** 🔹 Handle date selection */
  const handleDateChange = (value) => {
    if (value instanceof Date) {
      setSelectedDate(value);
      sessionStorage.setItem("selectedDate", value.toISOString());
      sessionStorage.setItem("selectedDateTimestamp", Date.now());
    }
  };

  /** 🔹 Reset initialLoad when the month changes */
  const handleActiveStartDateChange = ({ activeStartDate }) => {
    if (adminLevel >= 2 || club_nick === "personal") {
      setInitialLoad(false);
    }
  };

  return (
    <div>
      <Calendar
        onChange={handleDateChange}
        onActiveStartDateChange={handleActiveStartDateChange}
        value={selectedDate}
        calendarType="gregory"
        formatDay={(locale, date) => moment(date).format("D")}
        tileContent={memoizedTileContent}
        tileClassName={({ date }) =>
          holidays.holidaysWithYear.includes(
            moment(date).format("YYYY-MM-DD")
          ) ||
          holidays.holidaysWithoutYear.includes(moment(date).format("MM-DD"))
            ? "holiday"
            : null
        }
      />
      <ActivityCardList
        list={activityList}
        date={moment(selectedDate).format("YYYY-MM-DD")}
        club_nick={club_nick}
        memberId={memberId}
        crewId={crewId}
        adminLevel={adminLevel}
        isRestricted={isRestricted}
      />
    </div>
  );
}

export default ActivityCalendar;
