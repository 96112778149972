import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Table, TableBody, TableRow, TableCell } from "@mui/material";
import moment from "moment";
import "moment/locale/ko";
import conf from "../../config/conf_sql.json";
import Linkify from "react-linkify";
import ApiHeader from "../Common/ApiHeader";

function ActivityInfo({ clubId, actData }) {
  const [activityData, setActivityData] = useState([]);
  const [clubLevels, setClubLevels] = useState({});

  useEffect(() => {
    setActivityData(actData);
    ApiHeader.get(`/api/v1/clubs/${clubId}`).then((response) => {
      setClubLevels(response.data.levels && JSON.parse(response.data.levels));
    });
  }, [clubId, actData]);

  return (
    <div>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>날짜</TableCell>
            <TableCell>
              {moment(activityData.activity_time)
                .subtract(conf.time_offset)
                .format("Y년 M월 D일")}
              (
              {moment(activityData.activity_time)
                .subtract(conf.time_offset)
                .format("ddd")}
              ){" "}
              {moment(activityData.activity_time)
                .subtract(conf.time_offset)
                .format("A h시 mm분")}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>모임</TableCell>
            <TableCell>{activityData.name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>장소</TableCell>
            <TableCell>
              {activityData.location}
              {activityData.location_url &&
              activityData.location_url.length >= 2 ? (
                <Link
                  to={activityData.location_url}
                  style={{ color: "inherit", textDecoration: "inherit" }}
                >
                  {" "}
                  (지도보기)
                </Link>
              ) : (
                ""
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>정원</TableCell>
            <TableCell style={{ whiteSpace: "pre-wrap" }}>
              {activityData.max_crew} &nbsp; (
              {activityData.state === 2 ? "정규모임" : "비정규모임"})
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ whiteSpace: "nowrap" }}>소그룹</TableCell>
            <TableCell style={{ whiteSpace: "pre-wrap" }}>
              {activityData.subgroup}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>정보</TableCell>
            <TableCell style={{ whiteSpace: "pre-wrap" }}>
              <Linkify>{activityData.info}</Linkify>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>등급</TableCell>
            <TableCell style={{ whiteSpace: "pre-wrap" }}>
              {clubLevels[activityData.attend_level]} 이상
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
}

export default ActivityInfo;
