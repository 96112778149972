import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Radio,
  RadioGroup,
  FormControlLabel,
  Button,
  Stack,
  TextField,
  Typography,
  Toolbar,
  InputBase,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import moment from "moment";
import conf from "../../config/conf_sql.json";
import Hashids from "hashids";
const hashids = new Hashids(conf.secret, 10);
import ApiHeader from "../Common/ApiHeader";
import ErrorHandler from "../Common/ErrorHandler";

function AttendeesList({ club_nick, adminLevel }) {
  const [startDate, setStartDate] = useState(
    moment()
      .subtract(conf.time_offset)
      .subtract(1, "months")
      .startOf("month")
      .format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment().subtract(conf.time_offset).format("YYYY-MM-DD")
  );
  const [state, setState] = useState(2);
  const [attendeesList, setAttendeesList] = useState([]);
  const [inquiry, setInquiry] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (adminLevel < 7) return;
    const api =
      state > 0
        ? `/api/v1/crews/club/${club_nick}/attendees/from/${startDate}/to/${endDate}/state/${state}`
        : `/api/v1/crews/club/${club_nick}/noshow/from/${startDate}/to/${endDate}`;
    ApiHeader.get(api)
      .then((response) => {
        setAttendeesList(response.data);
      })
      .catch((e) => {
        ErrorHandler(e, "AttendeesList.js, useEffect, get attendees users");
      });
  }, [club_nick, adminLevel, inquiry]);

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    } else if (sortConfig.key === key && sortConfig.direction === "desc") {
      direction = null;
      key = null;
    }
    setSortConfig({ key, direction });
  };

  const sortedList = React.useMemo(() => {
    if (sortConfig.key && sortConfig.direction) {
      return [...attendeesList].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? 1 : -1;
        }
        return 0;
      });
    }
    return attendeesList;
  }, [attendeesList, sortConfig]);

  const filterSortData = sortedList.filter((row) =>
    row.crew_name ? row.crew_name.toLowerCase().includes(searchTerm) : false
  );

  const handleChange = (e) => {
    try {
      setSearchTerm(e.target.value.toLowerCase());
    } catch (e) {
      ErrorHandler(e, "CrewList.js, handleChange");
    }
  };

  return (
    <div>
      <Stack direction="row" spacing={3} sx={{ mt: 3, ml: 3, mb: 1 }}>
        <TextField
          required
          id="startDate"
          label="Start Date"
          type="date"
          InputLabelProps={{ shrink: true }}
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          size="small"
        />

        <TextField
          required
          id="endDate"
          label="End Date"
          type="date"
          InputLabelProps={{ shrink: true }}
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          size="small"
        />
      </Stack>
      <Stack
        direction="row"
        spacing={2}
        sx={{
          mr: 3,
          display: "flex",
          ml: "auto",
          justifyContent: "flex-end",
        }}
      >
        <RadioGroup
          row
          aria-labelledby="state"
          name="state"
          value={state == 2 ? "2" : state == 1 ? "1" : "0"}
          onChange={(e) => setState(e.target.value)}
        >
          <FormControlLabel
            value="2"
            control={<Radio size="small" />}
            label="정규"
          />
          <FormControlLabel
            value="1"
            control={<Radio size="small" />}
            label="비정규"
          />
          <FormControlLabel
            value="0"
            control={<Radio size="small" />}
            label="노쇼"
          />
        </RadioGroup>
        <Button variant="contained" onClick={() => setInquiry(!inquiry)}>
          조회
        </Button>
      </Stack>
      <Toolbar key="tb">
        <Typography key="tg1" variant="h7">
          Crew
        </Typography>
        <Typography
          key="tg2"
          noWrap
          component="div"
          sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
        />
        &ensp; <SearchIcon />
        <InputBase
          key="ib"
          type="text"
          className="search"
          onChange={handleChange}
          placeholder="Search..."
        />
      </Toolbar>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell
              onClick={() => handleSort("crew_name")}
              sx={{ cursor: "pointer" }}
            >
              이름
              {sortConfig.key === "crew_name"
                ? sortConfig.direction === "asc"
                  ? " ▲"
                  : sortConfig.direction === "desc"
                  ? " ▼"
                  : ""
                : ""}
            </TableCell>
            <TableCell
              onClick={() => handleSort("created_month")}
              sx={{ cursor: "pointer" }}
            >
              가입월
              {sortConfig.key === "created_month"
                ? sortConfig.direction === "asc"
                  ? " ▲"
                  : sortConfig.direction === "desc"
                  ? " ▼"
                  : ""
                : ""}
            </TableCell>
            <TableCell
              onClick={() => handleSort("attendance_count")}
              sx={{ cursor: "pointer" }}
            >
              {state > 0 ? "출석수" : "노쇼수"}
              {sortConfig.key === "attendance_count"
                ? sortConfig.direction === "asc"
                  ? " ▲"
                  : sortConfig.direction === "desc"
                  ? " ▼"
                  : ""
                : ""}
            </TableCell>
          </TableRow>
        </TableHead>
        {adminLevel >= 7 && (
          <TableBody>
            {filterSortData.map((row) => (
              <TableRow key={row.crew_id}>
                <TableCell
                  key={row.crew_id}
                  sx={{
                    minWidth: 48,
                    maxWidth: "12ch",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  <Link
                    to={`/${club_nick}/crew/${hashids.encode(row.crew_id)}`}
                    style={{ color: "#3f50b5", textDecoration: "inherit" }}
                  >
                    {row.crew_name}
                  </Link>
                </TableCell>
                <TableCell>{row.created_month}</TableCell>
                <TableCell>{row.attendance_count}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>
    </div>
  );
}

export default AttendeesList;
