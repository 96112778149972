import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import TopAppBar from "../Main/TopAppBar";
import ClubImage from "./ClubImage";
import ActivityList from "../Activity/ActivityList";
import useCrewData from "../Common/CrewData";
import { Paper, Box, CircularProgress } from "@mui/material";
import NoticeList from "../Notice/NoticeList";
import ClubPublicInfo from "./ClubPublicInfo";
import axios from "axios";
import ErrorHandler from "../Common/ErrorHandler";
import conf from "../../config/conf_sql.json";
import NotFound from "../NotFound";

function ClubMain() {
  const { club_nick } = useParams();
  const { memberId, clubId, crewId, adminLevel } = useCrewData(club_nick);
  const [club, setClub] = useState("");
  const [loading, setLoading] = useState(true);
  const linkto =
    club_nick.toLocaleUpperCase() === "YRC"
      ? `https://open.kakao.com/o/gvOlNToc`
      : club_nick.toLocaleUpperCase() === "JTR"
      ? `https://open.kakao.com/o/g29Q1e1f`
      : ``;
  const welcomePath =
    conf.media + "/uploads/" + club_nick.toLowerCase() + "_welcome.png";

  useEffect(() => {
    axios
      .get(`/api/v1/clubs/list`)
      .then((response) => {
        const club = response.data.find(
          (club) => club.nick.toUpperCase() === club_nick.toUpperCase()
        );
        if (club) {
          setClub(club);
        }
        setLoading(false);
      })
      .catch((e) => {
        ErrorHandler(e, "ClubMain.js, get club data");
      });
  }, [club_nick]);

  return (
    <div>
      <TopAppBar club_nick={club_nick} />
      {!loading && club !== "" && adminLevel > 0 ? (
        <div className="Main">
          <Link to={linkto} target="blank">
            <Paper
              key="image"
              elevation={0}
              sx={{ margin: 2, backgroundColor: "transparent" }}
            >
              <img
                key={"welcome"}
                className="img"
                alt={club_nick}
                src={welcomePath}
              />
            </Paper>
          </Link>
          <NoticeList
            order="desc"
            text="Latest Notices"
            api={`/api/v1/notices/club_nick/${club_nick}/list/category/공지`}
            club_nick={club_nick}
            crew_id={crewId}
            club_id={clubId}
            crew_nick={crewId}
            admin_level={adminLevel}
            limit={3}
          />
          <br />
          <ActivityList
            text="Following Activities"
            order="asc"
            api={`/api/v1/activities/club/${clubId}/following/day`}
            club_nick={club_nick}
            adminLevel={adminLevel}
          />
          <ClubImage club_nick={club_nick} />
        </div>
      ) : !loading && club !== "" ? (
        <ClubPublicInfo memberId={memberId} crewId={crewId} />
      ) : !loading ? (
        <NotFound />
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ height: "50vh" }}
        >
          <CircularProgress />
        </Box>
      )}
    </div>
  );
}

export default ClubMain;
