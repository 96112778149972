import React from "react";
import ClubList from "./ClubList";
import Welcome from "./Welcome";
import ActivityCalendar from "../Activity/ActivityCalendar";
import { Typography, Box, CircularProgress } from "@mui/material";
import TopAppBar from "./TopAppBar";
import useCrewData from "../Common/CrewData";

function Main() {
  const { memberId, clubId, crewId, adminLevel } = useCrewData(undefined);

  return (
    <div>
      <TopAppBar />
      {memberId === "" ? (
        <Welcome />
      ) : memberId === null ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ height: "50vh" }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <div className="Main">
          <ClubList memberId={memberId} />
          <Typography key="tg_mine" variant="h6" sx={{ ml: 2, mb: 1 }}>
            My Schedule
          </Typography>
          <ActivityCalendar club_nick={"personal"} />
        </div>
      )}
    </div>
  );
}

export default Main;
