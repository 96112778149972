import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import moment from "moment";
import conf from "../../config/conf_sql.json";
import Hashids from "hashids";
const hashids = new Hashids(conf.secret, 10);
import ApiHeader from "../Common/ApiHeader";
import ErrorHandler from "../Common/ErrorHandler";

function BanList({ club_nick, adminLevel }) {
  const [banList, setBanList] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);

  useEffect(() => {
    if (adminLevel < 7) return;
    ApiHeader.get(
      `/api/v1/crews/club/${club_nick}/admin-level/-1/crews?state=-1`
    )
      .then((response) => {
        setBanList(response.data);
      })
      .catch((e) => {
        ErrorHandler(e, "BanList.js, useEffect, get banned users");
      });
  }, [club_nick, adminLevel]);

  const handleUnban = (id) => {
    ApiHeader.put(`/api/v1/crews/${hashids.encode(id)}/state/0`)
      .then(() => {
        setBanList(banList.filter((user) => user.id !== id));
        handleClose();
      })
      .catch((e) => {
        ErrorHandler(e, "BanList.js, handleUnban, unban user");
      });
  };

  const handleClickOpen = (id) => {
    setSelectedUserId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedUserId(null);
  };

  return (
    <div>
      <Typography sx={{ mt: 2, ml: 2 }}>강퇴된 크루 리스트</Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>이름</TableCell>
            <TableCell>강퇴날짜</TableCell>
            <TableCell>강퇴</TableCell>
          </TableRow>
        </TableHead>
        {adminLevel >= 7 && (
          <TableBody>
            {banList.map((user) => (
              <TableRow key={user.id}>
                <TableCell
                  key={user.id}
                  sx={{
                    minWidth: 48,
                    maxWidth: "12ch",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {user.nick}
                </TableCell>
                <TableCell>
                  {moment(user.updated_time).format("YYYY-MM-DD")}
                </TableCell>
                <TableCell>
                  {adminLevel >= 8 && (
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => handleClickOpen(user.id)}
                    >
                      해제
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>해제 확인</DialogTitle>
        <DialogContent>
          <DialogContentText>
            이 사용자의 강퇴를 해제하시겠습니까?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            취소
          </Button>
          <Button
            onClick={() => handleUnban(selectedUserId)}
            color="primary"
            autoFocus
          >
            해제
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default BanList;
