import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { Typography, Button, CircularProgress, Box } from "@mui/material";
import ClubImage from "./ClubImage";
import axios from "axios";
import ErrorHandler from "../Common/ErrorHandler";

function ClubPublicInfo({ memberId, crewId }) {
  const { club_nick } = useParams();
  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const [count, setCount] = useState(0);
  const [clubInfo, setClubInfo] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get(`/api/v1/clubs/information/${club_nick}`)
      .then((response) => {
        setName(response.data.name);
        setCategory(response.data.category);
        setCount(response.data.crew_count);
        setClubInfo(response.data.info);
      })
      .catch((e) => {
        ErrorHandler(e, "ClubPublicInfo.js, get club info");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [club_nick]);

  // memberId 또는 crewId가 아직 null이면 로딩 화면 표시
  if (loading || memberId === null || crewId === null) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ height: "50vh" }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      {crewId === "" && <ClubImage club_nick={club_nick} />}
      {memberId !== "" && crewId === "" && (
        <Link
          to={`/${club_nick}/signup`}
          state={{ timeAuthorized: Date.now() }}
          style={{ textDecoration: "none" }}
        >
          <Button
            variant="contained"
            sx={{
              fontSize: "20px",
              display: "block",
              margin: "auto",
              width: "95%",
            }}
          >{`${club_nick.toUpperCase()} 가입`}</Button>
        </Link>
      )}
      <Typography style={{ margin: 20, whiteSpace: "pre-wrap" }}>
        [{category}] {name} 👥{count}
      </Typography>
      <Typography style={{ margin: 20, whiteSpace: "pre-wrap" }}>
        {clubInfo}
      </Typography>
    </>
  );
}

export default ClubPublicInfo;
