import React from "react";
import { Typography, Box } from "@mui/material";
import TopAppBar from "./Main/TopAppBar";

function NotFound() {
  return (
    <div>
      <TopAppBar />
      <br />
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ height: "50vh" }}
      >
        <Typography variant="h6" color="error">
          Page not found 404 Error
        </Typography>
      </Box>
      <br />
    </div>
  );
}

export default NotFound;
