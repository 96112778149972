import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
} from "@mui/material";
import conf from "../../config/conf_sql.json";
import Hashids from "hashids";
const hashids = new Hashids(conf.secret, 10);
import ApiHeader from "../Common/ApiHeader";
import ErrorHandler from "../Common/ErrorHandler";

function CancelList({ activity_id, club_nick }) {
  const [cancelList, setCancelList] = useState([]);

  useEffect(() => {
    ApiHeader.get(`/api/v1/activities/${activity_id}/crews/cancel`)
      .then((response) => {
        setCancelList(response.data);
      })
      .catch((e) => {
        ErrorHandler(e, "CancelList.js, useEffect, get canceled users");
      });
  }, [activity_id]);

  return (
    <div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>이름</TableCell>
            <TableCell>모임 전 취소 시점</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {cancelList.map((user) => (
            <TableRow key={user.id}>
              <TableCell
                key={user.id}
                sx={{
                  minWidth: 48,
                  maxWidth: "12ch",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                <Link
                  to={`/${club_nick}/crew/${hashids.encode(user.id)}`}
                  style={{ color: "#3f50b5", textDecoration: "inherit" }}
                >
                  {user.nick}
                </Link>
              </TableCell>
              <TableCell>{user.time_difference}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}

export default CancelList;
