import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Button,
  Stack,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  CircularProgress,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TopAppBar from "../Main/TopAppBar";
import ActivityInfo from "./ActivityInfo";
import CrewList from "../Crew/CrewList";
import MessageList from "../Message/MessageList";
import ActivityCard from "./ActivityCard";
import useCrewData from "../Common/CrewData";
import moment from "moment";
import conf from "../../config/conf_sql.json";
import ShareIcon from "@mui/icons-material/Share";
import ApiHeader from "../Common/ApiHeader";
import Hashids from "hashids";
const hashids = new Hashids(conf.secret, 10);
import ErrorHandler from "../Common/ErrorHandler";
import ClubPublicInfo from "../Club/ClubPublicInfo";
import LikeButton from "../Common/LikeButton";
import CancelList from "../Crew/CancelList";

function Activity() {
  const { club_nick, activity_id } = useParams();
  const { memberId, clubId, crewId, adminLevel } = useCrewData(club_nick);

  const navigate = useNavigate();
  const [activity, setActivity] = useState("");
  const [crewNick, setCrewNick] = useState("");
  const [activityChanged, setActivityChanged] = useState(false);
  const [correctPassword, setCorrectPassword] = useState(false);
  const [isRestricted, setIsRestricted] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleActivityChange = () => {
    setActivityChanged(!activityChanged);
  };

  useEffect(() => {
    if (crewId === "") return;
    ApiHeader.get(`/api/v1/crews/${crewId}`)
      .then((response) => {
        if (response.data === null) return;
        setCrewNick(response.data.nick);
      })
      .catch((e) => {
        ErrorHandler(e, "Activity.js, get crew nick");
      });

    ApiHeader.get(`/api/v1/attendees/activity/crew/${crewId}/cancel/count`)
      .then((response) => {
        if (response.data === null) return;
        response.data.count > 0 && setIsRestricted(true);
      })
      .catch((e) => {
        ErrorHandler(e, "Activity.js, get cancel count");
      });

    ApiHeader.get(`/api/v1/attendees/activity/crew/${crewId}/noshow/count`)
      .then((response) => {
        if (response.data === null) return;
        const { count, last_noshow_time } = response.data;

        if (count > 0) {
          const restrictionDays = count * 7;
          const now = moment();
          const restrictedUntil = moment(last_noshow_time)
            .subtract(conf.time_offset)
            .add(restrictionDays, "days");

          if (now.isBefore(restrictedUntil)) {
            setIsRestricted(true);
          }
        }
      })
      .catch((e) => {
        ErrorHandler(e, "Activity.js, get noshow count");
      });
  }, [crewId]);

  useEffect(() => {
    if (adminLevel === 0 || activity_id === "") return;
    ApiHeader.get(`/api/v1/activities/${activity_id}`)
      .then((response) => {
        if (response.data === null) return;
        setLoading(false);
        setActivity(response.data);
        setCorrectPassword(
          ((moment(response.data.created_time).minute() * 60 +
            moment(response.data.created_time).second()) %
            90) +
            10
        );
      })
      .catch((e) => {
        ErrorHandler(e, "Activity.js, get activity data");
      });
  }, [adminLevel, activity_id]);

  const copyApi = async () => {
    const data = {
      activity_time: activity.activity_time,
      name: activity.name,
      max_crew: activity.max_crew,
      location: activity.location,
      location_url: activity.location_url,
      subgroup: activity.subgroup,
      info: activity.info,
      attend_level: activity.attend_level,
      state: activity.state,
      type: "post",
    };
    try {
      navigate(`/${club_nick}/activity/create`, {
        state: {
          ...data, // 기존 데이터 병합
          timeAuthorized: Date.now(), // timeAuthorized 추가
        },
      });
    } catch (e) {
      ErrorHandler(e, "Activity.js, copyApi");
    }
  };

  const modifyApi = async () => {
    const data = {
      activity_time: activity.activity_time,
      name: activity.name,
      max_crew: activity.max_crew,
      location: activity.location,
      location_url: activity.location_url,
      subgroup: activity.subgroup,
      info: activity.info,
      attend_level: activity.attend_level,
      state: activity.state,
      type: "put",
    };
    try {
      navigate(`/${club_nick}/activity/modify/${hashids.encode(activity.id)}`, {
        state: {
          ...data, // 기존 데이터 병합
          timeAuthorized: Date.now(), // timeAuthorized 추가
        },
      });
    } catch (e) {
      ErrorHandler(e, "Activity.js, modifyApi");
    }
  };

  const [open, setOpen] = React.useState(false);
  const eventClick = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleAgree = () => {
    deleteApi();
    setOpen(false);
  };

  const deleteApi = async () => {
    try {
      const response = await ApiHeader.put(
        `/api/v1/activities/delete/${hashids.encode(activity.id)}`
      );
      navigate(-1);
    } catch (e) {
      ErrorHandler(e, "Activity.js, deleteApi");
    }
  };

  const isBeforeActivityEnd = (activityTime) => {
    const now = moment();
    const end = moment(activityTime).subtract(conf.time_offset).add(3, "hours");
    return now.isBefore(end);
  };

  const isAfterActivityOpen = (activityTime) => {
    const now = moment();
    const open = moment(activityTime)
      .subtract(conf.time_offset)
      .subtract(1, "hours");
    return now.isAfter(open);
  };

  // 클립보드에 복사하는 함수
  const [copySuccess, setCopySuccess] = useState("");
  const handleCopy = () => {
    const textToCopy = `${activity.name}
시간: ${moment(activity.activity_time)
      .subtract(conf.time_offset)
      .format("M/D(ddd) A h:mm")}
장소: ${activity.location}
${window.location.href}`;

    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          setCopySuccess("Copied to clipboard!");
          setTimeout(() => setCopySuccess(""), 2000); // 메시지 2초 후 사라짐
        })
        .catch((e) => {
          ErrorHandler(e, "Activity.js, handleCopy if true");
        });
    } else {
      // Fallback method for browsers that don't support navigator.clipboard
      const textArea = document.createElement("textarea");
      textArea.value = textToCopy;
      document.body.appendChild(textArea);
      textArea.select();
      try {
        document.execCommand("copy");
        setCopySuccess("Copied to clipboard!");
        setTimeout(() => setCopySuccess(""), 2000); // 메시지 2초 후 사라짐
      } catch (e) {
        ErrorHandler(e, "Activity.js, handleCopy else");
      }
      document.body.removeChild(textArea);
    }
  };

  return (
    <div>
      <TopAppBar club_nick={club_nick} />
      {!loading && adminLevel > 0 && activity !== "" ? (
        <div>
          <ActivityCard
            row={activity}
            onActivityChange={handleActivityChange}
            club_nick={club_nick}
            memberId={memberId}
            crewId={crewId}
            adminLevel={adminLevel}
            isRestricted={isRestricted}
          />
          {(adminLevel >= 7 ||
            activity.crew_id === hashids.decode(crewId)[0]) && (
            <Stack
              direction="row"
              spacing={2}
              sx={{
                mr: 3,
                display: "flex",
                ml: "auto",
                justifyContent: "flex-end",
              }}
            >
              <Button onClick={handleCopy} sx={{ display: "flex", ml: "auto" }}>
                <ShareIcon />
              </Button>
              {copySuccess && (
                <div
                  style={{
                    position: "fixed",
                    bottom: "10px",
                    left: "50%",
                    transform: "translateX(-50%)",
                    backgroundColor: "lightgreen",
                    padding: "10px",
                    borderRadius: "5px",
                  }}
                >
                  {copySuccess}
                </div>
              )}
              <Button
                variant="contained"
                onClick={copyApi}
                color="success"
                sx={{ display: "flex", ml: "auto" }}
              >
                복제
              </Button>
              {isBeforeActivityEnd(activity.activity_time) && (
                <React.Fragment>
                  {(adminLevel >= 8 ||
                    activity.crew_id === hashids.decode(crewId)[0]) && (
                    <>
                      <Button
                        variant="contained"
                        onClick={modifyApi}
                        sx={{ display: "flex", ml: "auto" }}
                      >
                        수정
                      </Button>
                      <Button
                        variant="contained"
                        onClick={eventClick}
                        color="error"
                        sx={{ display: "flex", ml: "auto" }}
                      >
                        삭제
                      </Button>
                      <Dialog open={open} onClose={handleClose}>
                        <DialogTitle id="alert-dialog-title">
                          모임 삭제 확인
                        </DialogTitle>
                        <DialogContent>
                          <DialogContentText id="alert-dialog-description">
                            모임 삭제 하시겠습니까?
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={handleClose}>취소</Button>
                          <Button onClick={handleAgree} autoFocus>
                            삭제
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </>
                  )}
                  {isAfterActivityOpen(activity.activity_time) && (
                    <Typography variant="h5">{correctPassword}</Typography>
                  )}
                </React.Fragment>
              )}
            </Stack>
          )}
          <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
            <Typography>&ensp;Activity</Typography>
            <LikeButton
              crew_id={crewId}
              table_type={"activity"}
              table_id={activity_id}
              size={18}
            />
          </div>
          <ActivityInfo clubId={clubId} actData={activity} />
          <br />
          <CrewList
            order="asc"
            api={`/api/v1/activities/${activity_id}/crews?state=0`}
            lst_col={["이름", "소그룹", ""]}
            activityChanged={activityChanged}
            activity_id={activity_id}
            activity_time={activity.activity_time}
            activity_subgroup={activity.subgroup}
            activity_owner={activity.crew_id}
            text="Attendees"
            club_nick={club_nick}
            correctPassword={correctPassword}
          />
          {(adminLevel >= 7 ||
            activity.crew_id === hashids.decode(crewId)[0]) && (
            <>
              <br />
              <Accordion
                sx={{
                  border: "1px solid #ddd", // 선을 얇게 조정
                  boxShadow: "none", // 기본 그림자 제거
                  "&:before": { display: "none" }, // MUI 기본 라인 제거
                }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>참가 취소자 보기</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <CancelList activity_id={activity_id} club_nick={club_nick} />
                </AccordionDetails>
              </Accordion>
            </>
          )}
          <MessageList
            order="asc"
            api={`/api/v1/activities/${activity_id}/messages`}
            crew_id={crewId}
            club_id={clubId}
            crew_nick={crewNick}
            activity_id={activity_id}
            text="Voice of Crew"
            club_nick={club_nick}
            admin_level={adminLevel}
          />
        </div>
      ) : !loading && adminLevel > 0 && activity === "" ? (
        <>
          <br />
          <Box display="flex" justifyContent="center" alignItems="center">
            <Typography variant="h6" color="error">
              모임 정보를 찾을 수 없습니다.
            </Typography>
          </Box>
          <br />
        </>
      ) : !loading ? (
        <>
          <ClubPublicInfo memberId={memberId} crewId={crewId} />
        </>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ height: "50vh" }}
        >
          <CircularProgress />
        </Box>
      )}
    </div>
  );
}

export default Activity;
