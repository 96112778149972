import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Typography } from "@mui/material";
import TopAppBar from "../Main/TopAppBar";
import CrewInfo from "./CrewInfo";
import ActivityList from "../Activity/ActivityList";
import useCrewData from "../Common/CrewData";
import ClubPublicInfo from "../Club/ClubPublicInfo";

function Crew() {
  const { club_nick, crew_id } = useParams();
  const { memberId, clubId, crewId, adminLevel } = useCrewData(club_nick);
  let token = localStorage.getItem("TOKEN_CREWWITH");
  if (token) {
    token = token.replace(/"/g, "");
  }

  return (
    <div>
      <TopAppBar club_nick={club_nick} />
      {adminLevel >= 3 || crew_id === crewId ? (
        <>
          <Typography key="tg">&ensp; Profile</Typography>
          <CrewInfo
            api={
              adminLevel >= 8
                ? `/api/v1/crews/${crew_id}/members/decrypt/${token}`
                : adminLevel >= 7
                ? `/api/v1/crews/${crew_id}/members/detail`
                : `/api/v1/crews/${crew_id}/members`
            }
            adminLevel={adminLevel}
            club_nick={club_nick}
          />
          <br />
          <ActivityList
            order="desc"
            api={`/api/v1/crews/${crew_id}/activities`}
            text="Participatory Activities"
            needSearch={true}
          />
        </>
      ) : (
        <ClubPublicInfo memberId={memberId} crewId={crewId} />
      )}
    </div>
  );
}

export default Crew;
