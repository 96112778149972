import { useEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";
import ApiHeader from "./ApiHeader";
import conf from "../../config/conf_sql.json";
import Hashids from "hashids";
const hashids = new Hashids(conf.secret, 10);

const fetchCrewData = async (token, club_nick) => {
  try {
    const response = await ApiHeader.get(
      `/api/v1/accesslogs/${token}/clubs/${club_nick}/crews`
    );
    return response.data;
  } catch (error) {
    return [];
  }
};

const fetchMemberData = async (token) => {
  try {
    const response = await ApiHeader.get(`/api/v1/accesslogs/${token}/members`);
    return response.data;
  } catch (error) {
    return [];
  }
};

const updateLocalStorage = (
  token,
  club_nick,
  memberId,
  clubId,
  crewId,
  adminLevel
) => {
  secureLocalStorage.setItem("DATA_CREWWITH", {
    token,
    club_nick,
    member_id: memberId,
    club_id: clubId,
    crew_id: crewId,
    admin_level: adminLevel,
    date: new Date(),
  });
};

const useCrewData = (club_nick) => {
  const [memberId, setMemberId] = useState(null);
  const [clubId, setClubId] = useState(null);
  const [crewId, setCrewId] = useState(null);
  const [adminLevel, setAdminLevel] = useState(null);

  useEffect(() => {
    const fetchDataAndUpdateState = async () => {
      let token = localStorage.getItem("TOKEN_CREWWITH");
      if (token) token = token.replace(/"/g, "");

      const sls = secureLocalStorage.getItem("DATA_CREWWITH");
      const isCachedDataValid =
        sls &&
        token &&
        sls.token === token &&
        sls.club_nick === club_nick &&
        sls.member_id !== "" &&
        sls.crew_id !== "" &&
        sls.date > new Date() - 60 * 60 * 1000;

      if (isCachedDataValid) {
        setMemberId(sls.member_id);
        setClubId(sls.club_id);
        setCrewId(sls.crew_id);
        setAdminLevel(sls.admin_level);
        return;
      }

      let crewData = [];
      if (token && club_nick) {
        crewData = await fetchCrewData(token, club_nick);
      }

      if (crewData.length > 0) {
        const { member_id, club_id, crew_id, state, admin_level } = crewData[0];
        const encodedMemberId = hashids.encode(member_id);
        const encodedClubId = hashids.encode(club_id);
        const encodedCrewId = state === 0 ? "" : hashids.encode(crew_id);

        setMemberId(encodedMemberId);
        setClubId(encodedClubId);
        setCrewId(encodedCrewId);
        setAdminLevel(state === 0 ? 0 : admin_level);

        updateLocalStorage(
          token,
          club_nick,
          encodedMemberId,
          encodedClubId,
          encodedCrewId,
          admin_level
        );

        // refreshToken();
      } else {
        const memberData = await fetchMemberData(token);
        const encodedMemberId =
          memberData.length > 0 && memberData[0].state > 0
            ? hashids.encode(memberData[0].id)
            : "";

        setMemberId(encodedMemberId);
        setClubId("");
        setCrewId("");
        setAdminLevel(0);

        updateLocalStorage(token, club_nick, encodedMemberId, "", "", 0);
      }
    };

    fetchDataAndUpdateState();
  }, [club_nick]);

  return { memberId, clubId, crewId, adminLevel };
};

export default useCrewData;
