import React from "react";
import { useParams, Link, useLocation, useNavigate } from "react-router-dom";
import { Button, Box, Fab } from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import TopAppBar from "../Main/TopAppBar";
import NoticeList from "./NoticeList";
import useCrewData from "../Common/CrewData";
import ClubPublicInfo from "../Club/ClubPublicInfo";

const categories = ["공지", "자유글", "모임", "러닝", "대회", "마켓", "챌린지"];

function Bulletin() {
  const { club_nick } = useParams();
  const { memberId, clubId, crewId, adminLevel } = useCrewData(club_nick);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const category = searchParams.get("category");
  const navigate = useNavigate();

  const api =
    `/api/v1/notices/club_nick/${club_nick}/list` +
    (category ? `/category/${category}` : "");

  const handleCategoryClick = (selectedCategory) => {
    const newSearchParams = new URLSearchParams(location.search);
    newSearchParams.set("category", selectedCategory);
    navigate({
      pathname: location.pathname,
      search: newSearchParams.toString(),
    });
  };

  return (
    <div>
      <TopAppBar club_nick={club_nick} />
      {adminLevel > 0 ? (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              flexWrap: "wrap",
              ml: 2,
              mt: 2,
              gap: 1,
            }}
          >
            {categories.map((cat) => (
              <Button
                key={cat}
                variant={cat === category ? "contained" : "outlined"}
                sx={{
                  fontSize: "0.8rem",
                  padding: "2px 6px",
                  minWidth: "auto",
                }}
                onClick={() => handleCategoryClick(cat)}
              >
                {cat}
              </Button>
            ))}
          </Box>

          <NoticeList
            order="desc"
            text="Bulletin Board"
            api={api}
            club_nick={club_nick}
            crew_id={crewId}
            club_id={clubId}
            crew_nick={crewId}
            admin_level={adminLevel}
            needSearch={true}
          />
          <Fab
            color="primary"
            aria-label="add"
            sx={{
              position: "fixed",
              bottom: 16,
              right: 16,
              width: 48,
              height: 48,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Link
              to={`/${club_nick}/notice/create`}
              state={{ timeAuthorized: Date.now() }}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "inherit",
                textDecoration: "none",
                width: "100%",
                height: "100%",
              }}
            >
              <AddIcon fontSize="small" />
            </Link>
          </Fab>
        </>
      ) : (
        <ClubPublicInfo memberId={memberId} crewId={crewId} />
      )}
    </div>
  );
}

export default Bulletin;
