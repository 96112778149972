import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Stack } from "@mui/material";
import TopAppBar from "../Main/TopAppBar";
import CrewList from "./CrewList";
import useCrewData from "../Common/CrewData";
import ClubPublicInfo from "../Club/ClubPublicInfo";
import BanList from "./BanList";
import LimitList from "./LimitList";
import AttendeesList from "./AttendeesList";

function Crews() {
  const { club_nick } = useParams();
  const { memberId, clubId, crewId, adminLevel } = useCrewData(club_nick);
  const [view, setView] = useState("all");

  const handleViewChange = (newView) => {
    setView(newView);
  };

  if (crewId != null && adminLevel >= 5) {
    return (
      <div>
        <TopAppBar club_nick={club_nick} />
        {adminLevel >= 7 && (
          <Stack
            direction="row"
            spacing={2}
            sx={{ mt: 1, ml: 2, display: "flex" }}
          >
            <Button
              variant={view === "all" ? "contained" : "outlined"}
              size="small"
              sx={{ display: "flex", ml: "auto" }}
              onClick={() => handleViewChange("all")}
            >
              전체크루
            </Button>
            <Button
              variant={view === "attendees" ? "contained" : "outlined"}
              size="small"
              sx={{ display: "flex", ml: "auto" }}
              onClick={() => handleViewChange("attendees")}
            >
              출석수
            </Button>
            <Button
              variant={view === "limited" ? "contained" : "outlined"}
              size="small"
              sx={{ display: "flex", ml: "auto" }}
              onClick={() => handleViewChange("limited")}
            >
              제한크루
            </Button>
            <Button
              variant={view === "banned" ? "contained" : "outlined"}
              size="small"
              sx={{ display: "flex", ml: "auto" }}
              onClick={() => handleViewChange("banned")}
            >
              강퇴크루
            </Button>
          </Stack>
        )}
        {view === "all" && (
          <CrewList
            text="Crew"
            order="desc"
            lst_col={["이름", "크루등급", "가입일"]}
            api={`/api/v1/crews/club/${club_nick}/admin-level/10/crews?state=1&sign_name=under`}
            club_nick={club_nick}
          />
        )}

        {view === "attendees" && (
          <AttendeesList club_nick={club_nick} adminLevel={adminLevel} />
        )}

        {view === "limited" && (
          <LimitList club_nick={club_nick} adminLevel={adminLevel} />
        )}

        {view === "banned" && (
          <BanList club_nick={club_nick} adminLevel={adminLevel} />
        )}
      </div>
    );
  } else {
    return (
      <>
        <TopAppBar club_nick={club_nick} />
        <ClubPublicInfo memberId={memberId} crewId={crewId} />
      </>
    );
  }
}

export default Crews;
